<template>
  <f7-page class="cellar-page" @page:beforein="getProductList">
    <template #fixed>
      <CellarNavigationComponent type="back" :title="$t.getTranslation('LBL_CELLAR')" :search="seachTrigger" />
    </template>

    <div v-if="isGettingList" class="preloader-cont">
      <f7-preloader></f7-preloader>
    </div>

    <div class="product-container" v-if="filteredList.length">
      <template v-for="(product, ind) in filteredList" :key="ind">
        <ProductListCardComponent type="cellar" :action="removeCellar" :data="product" />
      </template>
    </div>

    <NoDataFoundComponent v-else :size="'sm'" type="full" :title="$t.getTranslation('LBL_NO_ITEMS_ADDED_TO_CELLAR')" />
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, computed, inject, defineAsyncComponent } from "vue";
import { get, post } from "@/utils/axios";
import { useStore } from "@/store";

// import CellarNavigationComponent from "@/components/navigations/CellarNavigationComponent.vue";
// import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";
// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

const CellarNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "cellar-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/CellarNavigationComponent.vue"));
const ProductListCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-list-card" */ /* webpackMode: "lazy" */ "@/components/cards/ProductListCardComponent.vue"));
const NoDataFoundComponent = defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue"));

export default defineComponent({
  name: "CellarListPage",
  components: {
    NoDataFoundComponent,
    CellarNavigationComponent,
    ProductListCardComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const store = useStore();

    const $t = inject("$translation");
    let isGettingList = ref(true);
    const productList = ref([]);
    const searchQuery = ref(null);

    const removeCellar = async (prod) => {
      let url = "/wishlist/item/remove";
      let ret = await post(url, { ProductKey: prod.ProductKey });
      if (!ret) return;

      // helpers.createNotification({
      //     type: "success",
      //     title: $t.getTranslation("LBL_SUCCESS"),
      //     message: $t.getTranslation("LBL_REMOVED_FROM_CELLAR"),
      // });
      getProductList();
    };

    const getProductList = async () => {
      let ret = await get("/wishlist/view", {});
      isGettingList.value = false;
      productList.value = ret.Wishlist.ItemList;
    };

    const seachTrigger = (search) => {
      searchQuery.value = search;
    };

    const filteredList = computed(() => {
      let filter = _.filter(productList.value, (ret) => {
        if (!searchQuery.value) return true;

        let found = _.filter(ret.Attributes, (r) => {
          return r.Name.toString().toLowerCase().indexOf(searchQuery.value) > -1 ? true : false;
        });

        if (found.length) {
          return true;
        }
        return false;
      });
      return filter;
    });

    onMounted(getProductList);
    return { filteredList, isGettingList, removeCellar, seachTrigger, getProductList };
  },
});
</script>
